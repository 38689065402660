import home_en from "../assets/header-mobile-en.png";
function Home() {
  return (
    <>
      <div className="flex items-center justify-center w-full h-screen">
        <div className="w-full max-w-[480px] px-5 py-16 font-bold text-center">
          <div className="relative w-full h-full">
            <img
              loading="lazy"
              srcSet={home_en}
              className="relative mt-8 w-full z-10"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
