import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUserData } from "../redux/slices/userSlices";
import useAuth from "../hooks/useAuthentication";
import Root from "../pages/Root";

const ProtectedRouter = () => {
  return <Root />;
};

export default ProtectedRouter;
