import React, { ChangeEvent } from "react";

type Props = {
  placeholder?: string;
  value: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
};

const CustomInput = ({
  placeholder,
  value,
  onChange,
  type = "text",
}: Props) => {
  const handleDateInput = (e: ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/\D/g, ""); // Chỉ giữ lại số
    if (input.length > 8) input = input.slice(0, 8); // Giới hạn 8 ký tự số

    let formattedDate = "";
    if (input.length > 0) formattedDate += input.slice(0, 2);
    if (input.length > 2) formattedDate += "/" + input.slice(2, 4);
    if (input.length > 4) formattedDate += "/" + input.slice(4, 8);

    e.target.value = formattedDate;
    onChange(e);
  };

  return (
    <div className="justify-center mt-1">
      <input
        type={type === "date" ? "text" : type}
        value={value}
        onChange={type === "date" ? handleDateInput : onChange}
        className="w-full py-2 pl-2 justify-center text-neutral-400 placeholder-neutral-400 bg-white rounded-lg border border-solid border-stone-300 font-medium"
        placeholder={type === "date" ? "dd/mm/yyyy" : placeholder}
      />
    </div>
  );
};

export default CustomInput;
