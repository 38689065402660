import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ValuePropsGetUser, DataUser } from "../../utils/interface.global";
import { showToast } from "../../utils/showToast";
import { loginUserByAPI } from "../../components/api/authentication";

//"user/login"
export const loginUser = createAsyncThunk(
  "user/login",
  async (
    { name, phone, email }: ValuePropsGetUser,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const props = {
        name,
        phone,
        email,
      };
      if (name === "" || phone === "" || email === "") {
        throw rejectWithValue("Vui lòng nhập đầy đủ thông tin");
      }
      const response = await loginUserByAPI(props);
      console.log("loginUser", response);
      if (response.status) {
        //showToast({ message: response.message, type: "success" });
        //console.log("fulfillWithValue", response);
        return fulfillWithValue(response.data);
      } else {
        //showToast({ type: "error", message: response.message });
        throw rejectWithValue(response.message);
      }
    } catch (error: any) {
      let message = "";
      if (error.message != undefined) {
        message = error.message;
      } else {
        message = error.payload;
      }
      console.log("error", error.payload, error.message);
      throw rejectWithValue(message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    status: false,
    userData: null,
    error: "",
    error_code: "",
  },
  reducers: {
    updateData: (state: any, action: any) => {
      const tmp = action.payload;
      if (tmp !== null) {
        state.userData = tmp;
        state.status = true;
      } else {
        state.userData = tmp;
        state.status = true;
      }
    },
    logout: (state: any) => {
      state.userData = null;
      state.status = false;
    },
  },
  extraReducers(builder) {
    builder
      // register user
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = true;
        state.userData = action.payload;
        state.error = "";
        state.error_code = "";
        //console.log("loginUser", action.payload);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = false;
        state.userData = null;
        state.error = action.payload as string;
        state.error_code = new Date().getTime().toString();
        console.log("rejected", state.error);
      });
  },
});

//Reducers
export const { logout, updateData } = userSlice.actions;

// actions
//export const getUserData = (state) => state.user?.userData;
export const getUserData = (state: { user: { userData: DataUser } }) =>
  state.user?.userData;
export const getUserStatus = (state: { user: { status: string } }) =>
  state.user?.status;
export const getUsertErr = (state: { user: { error: string } }) =>
  state.user?.error;
export const getUsertErrCode = (state: { user: { error_code: string } }) =>
  state.user?.error_code;

// reducer
export default userSlice.reducer;
