import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import bg from "../assets/bg.png";

export default function Root() {
  return (
    <>
      <div
        className="w-full mx-auto min-h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <Outlet />
      </div>
    </>
  );
}
