import { useEffect, useState } from "react";
import header_mobile_vi from "../assets/header-mobile-vi.png";
import logo from "../assets/logo.png";
import title_vi from "../assets/title_vi.png";
import dia_cau from "../assets/dia_cau.png";
import con_thuyen from "../assets/con_thuyen.png";

import CustomInput from "../components/Input";
import { getUserData, logout, updateData } from "../redux/slices/userSlices";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../utils/showToast";
import CustomSelect from "../components/Select";
import {
  postGetUserAPI,
  postRegisterAPI,
} from "../components/api/authentication";

const dataTitle = [
  { label: "Chọn", value: "" },
  { label: "Ông", value: "Ông" },
  { label: "Bà", value: "Bà" },
  { label: "Anh", value: "Anh" },
  { label: "Chị", value: "Chị" },
];

const dataTimeCheckIn = [
  { label: "Chọn", value: "" },
  {
    label: "Check-in: 10 Oct 2024 at 3 PM",
    value: "Check-in: 10 Oct 2024 at 3 PM",
  },
  {
    label: "Check-in: 11 Oct 2024 at 3 PM",
    value: "Check-in: 11 Oct 2024 at 3 PM",
  },
];
const dataHoTroDatXe = [
  { label: "Chọn", value: "" },
  { label: "Tôi không cần đặt xe đón", value: "0" },
  { label: "Tôi cần đặt xe đón", value: "1" },
];
const dataRegisterDay1 = [
  { label: "Chọn", value: "" },
  { label: "Không thể tham dự", value: "0" },
  { label: "Đăng ký tham dự", value: "1" },
];
const dataRegisterDay2Activites = [
  { label: "Chọn", value: "" },
  { label: "Giải Golf", value: "Giải Golf" },
  { label: "Tour tham quan Phú Quốc", value: "Tour tham quan Phú Quốc" },
  { label: "Hoạt động tự do", value: "Hoạt động tự do" },
];
const dataRegisterDay2Dinner = [
  { label: "Chọn", value: "" },
  { label: "Không thể tham dự", value: "0" },
  { label: "Đăng ký tham dự", value: "1" },
];
const dataTShirtSize = [
  { label: "Chọn", value: "" },
  { label: "XS", value: "XS" },
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "XXL", value: "XXL" },
  { label: "XXXL", value: "XXXL" },
];
const dataDietaryRestrictions = [
  { label: "Chọn", value: "" },
  { label: "Tôi không có chế độ ăn đặc biệt nào", value: "1" },
  {
    label: "Có",
    value: "2",
  },
];

function RegisterVI() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code, status } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [responseData, setResponseData] = useState<any>(null);
  const [step, setStep] = useState(1);
  const [isDone, setIsDone] = useState(false);
  const dataUser = useSelector(getUserData);

  const [formData, setFormData] = useState({
    token: code,
    status: status,
    language: "vi",
    title: "",
    name: "",
    phone: "",
    company: "",
    position: "",
    nationality: "",
    date_of_birth: "",
    passport_number: "",
    passport_expiration_date: "",
    passport_place_of_issue: "",
    time_request_check_in: "",
    airport_transfer_at: "",
    register_day_1: "",
    register_day_2_activites: "",
    handicap: "",
    register_day_2_dinner: "",
    t_shirt_size: "",
    dietary_restrictions: "",
    dietary_restrictions_others: "",
    other_questions: "",
  });

  useEffect(() => {
    if (status == "no") {
      postData();
    } else {
      getUser();
    }
  }, []);

  useEffect(() => {
    console.log("user", dataUser);
    if (dataUser) {
      if (dataUser.status == true) {
        setIsLoading(false);
        setIsDone(true);
      }
    }
  }, [dataUser]);

  const getUser = async () => {
    setIsLoading(true);
    setResponseData(null);
    try {
      const result = await postGetUserAPI(formData);

      console.log("result", result);

      if (result.status == false) {
        setResponseData(result);
      } else {
        setFormData({ ...formData, ...result.user });
        setIsEdit(result.is_edit);
        setIsLoading(false);
        if (result.user.time_register == "") {
          dispatch(logout());
          setResponseData(null);
          setIsLoading(false);
          setIsDone(false);
        } else {
          setIsDone(true);
        }
      }
    } catch (error) {
      setIsLoading(false);
      showToast({ type: "error", message: "Có lỗi xảy ra. vui lòng thử lại" });
    }
  };

  const handleNext = () => {
    console.log("formData", formData);
    if (
      formData.title == "" ||
      formData.name == "" ||
      formData.phone == "" ||
      formData.company == "" ||
      formData.position == "" ||
      formData.nationality == "" ||
      formData.date_of_birth == "" ||
      // formData.passport_number == "" ||
      // formData.passport_expiration_date == "" ||
      // formData.passport_place_of_issue == "" ||
      formData.time_request_check_in == "" ||
      formData.airport_transfer_at.toString() == ""
    ) {
      showToast({ type: "error", message: "Vui lòng nhập đầy đủ thông tin" });
    } else {
      setStep(2);
    }
  };

  const handlePrevious = () => {
    setStep(1);
  };

  const handleSubmit = async () => {
    console.log("formData", formData);
    if (
      formData.register_day_1 === "" ||
      formData.register_day_2_activites === "" ||
      formData.register_day_2_dinner === "" ||
      formData.t_shirt_size === ""
    ) {
      showToast({ type: "error", message: "Vui lòng nhập đầy đủ thông tin" });
    } else {
      postData();
    }
  };

  const postData = async () => {
    setIsLoading(true);
    setResponseData(null);
    try {
      const result = await postRegisterAPI(formData);

      console.log("result", result);

      if (result.status == false) {
        setResponseData(result);
      } else {
        dispatch(updateData(result));
        setIsLoading(false);
        setIsDone(true);
      }
    } catch (error) {
      setIsLoading(false);
      showToast({ type: "error", message: "Có lỗi xảy ra. vui lòng thử lại" });
    }
  };

  const onClosePopup = () => {
    setIsLoading(false);
  };

  const closePopupToChange = () => {
    setIsDone(false);
  };

  return (
    <>
      <div className="flex overflow-hidden relative flex-col ">
        <div className="flex relative flex-col w-full max-md:max-w-full">
          <div className="flex relative z-10 flex-col flex-1 self-center pt-6 w-full px-5 sm:px-0 sm:w-[1152px] max-w-full">
            <img
              loading="lazy"
              srcSet={header_mobile_vi}
              className="block sm:hidden object-contain z-0 self-center mt-8 w-full mb-8"
            />
            <img
              loading="lazy"
              srcSet={logo}
              className="hidden sm:block object-contain z-0 self-center mt-8 w-[221px] mb-8"
            />
            <img
              loading="lazy"
              srcSet={title_vi}
              className="hidden sm:block object-contain z-0 self-center max-w-full aspect-[7.3] w-[438px] mb-8"
            />
            <div className="hidden lg:block flex absolute right-0 top-2/4 z-0 flex-col w-80 max-w-full -translate-y-2/4 h-[267px] translate-x-[0%]">
              <div className="flex relative flex-col justify-center items-end px-16 py-9 w-full bg-blend-overlay aspect-[1.203] max-md:px-5">
                <img
                  loading="lazy"
                  srcSet={dia_cau}
                  className="object-cover absolute inset-0 size-full"
                />
              </div>
            </div>
            {/* Box nội dung */}
            <div className="flex justify-center w-full mb-8 relative z-10">
              <div
                className="w-full max-w-[480px] rounded-2xl overflow-hidden text-white"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(16, 65, 133, 0.6), rgba(16, 47, 99, 0.6))",
                  backdropFilter: "blur(5px)",
                }}
              >
                {step == 1 ? (
                  <>
                    <div className="p-6 text-xl font-black text-center text-white uppercase">
                      Thông tin cá nhân
                    </div>
                    <div
                      className="px-6 py-4 h-auto sm:h-[calc(100vh-350px)] sm:overflow-y-auto"
                      style={{
                        scrollbarWidth: "thin",
                        scrollbarColor: "#ffffff40 transparent",
                      }}
                    >
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Danh xưng{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomSelect
                            data={dataTitle}
                            placeholder="Danh xưng"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                title: e.target.value,
                              })
                            }
                            value={formData.title}
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Họ và tên{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomInput
                            type="text"
                            placeholder="Họ và tên"
                            value={formData.name}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                name: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Số điện thoại{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomInput
                            type="text"
                            placeholder="Số điện thoại"
                            value={formData.phone}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                phone: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Công ty{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomInput
                            type="text"
                            placeholder="Công ty"
                            value={formData.company}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                company: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Chức vụ{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomInput
                            type="text"
                            placeholder="Chức vụ"
                            value={formData.position}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                position: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Quốc tịch{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomInput
                            type="text"
                            placeholder="Quốc tịch"
                            value={formData.nationality}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                nationality: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Ngày tháng năm sinh{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <span className="italic text-xs">
                            Vui lòng cung cấp thông tin để hỗ trợ việc đón
                            khách.
                          </span>
                          <span className="italic text-xs">
                            ***Date format: dd/mm/yyyy
                          </span>

                          <CustomInput
                            type="date"
                            placeholder="Ngày tháng năm sinh"
                            value={formData.date_of_birth}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                date_of_birth: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Số hộ chiếu hoặc CCCD
                          </div>
                          <span className="italic text-xs">
                            Vui lòng cung cấp thông tin để hỗ trợ việc đón
                            khách.
                          </span>
                          <span className="italic text-xs">&nbsp;</span>
                          <CustomInput
                            type="text"
                            placeholder="Số hộ chiếu hoặc CCCD"
                            value={formData.passport_number}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                passport_number: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Ngày hết hạn hộ chiếu hoặc CCCD
                          </div>
                          <span className="italic text-xs">
                            ***Date format: dd/mm/yyyy
                          </span>
                          <CustomInput
                            type="date"
                            placeholder="Ngày hết hạn hộ chiếu hoặc CCCD"
                            value={formData.passport_expiration_date}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                passport_expiration_date: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Nơi cấp hộ chiếu hoặc CCCD
                          </div>
                          <span className="italic text-xs">&nbsp;</span>
                          <CustomInput
                            type="text"
                            placeholder="Nơi cấp hộ chiếu hoặc CCCD"
                            value={formData.passport_place_of_issue}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                passport_place_of_issue: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-4 text-white text-sm">
                        Chúng tôi sắp xếp phòng nghỉ cho khách tại New World Phu
                        Quoc Resort (từ ngày 10 đến ngày 12/10/2024) Hãy vui
                        lòng cung cấp ngày bạn muốn check-in khách sạn.{" "}
                        <span className="text-red-600 font-bold">*</span>
                        <br />
                        <span className="italic">Check in time: 03 PM</span>
                        <br />
                        <span className="italic">Check out time: 12 PM</span>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full whitespace-nowrap text-blue-950">
                        <CustomSelect
                          data={dataTimeCheckIn}
                          placeholder="check-in"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              time_request_check_in: e.target.value,
                            })
                          }
                          value={formData.time_request_check_in}
                        />
                      </div>
                      <div className="mt-4 text-white text-sm sm:text-base">
                        Bạn có cần BTC hỗ trợ đặt xe đón/tiễn tại sân bay Phú
                        Quốc? <span className="text-red-600 font-bold">*</span>
                      </div>
                      <div className="mt-4 text-white">
                        <CustomSelect
                          data={dataHoTroDatXe}
                          placeholder="Hỗ trợ đặt xe"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              airport_transfer_at: e.target.value,
                            })
                          }
                          value={formData.airport_transfer_at}
                        />
                      </div>
                      <button
                        onClick={handleNext}
                        className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                      >
                        Tiếp tục
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="p-4 sm:p-6 text-xl font-black text-center text-white uppercase">
                      đăng ký tham dự sự kiện
                    </div>
                    <div
                      className="px-6 py-2 sm:py-2 h-auto sm:h-[calc(100vh-350px)] sm:overflow-y-auto"
                      style={{
                        scrollbarWidth: "thin",
                        scrollbarColor: "#ffffff40 transparent",
                      }}
                    >
                      <div className="mt-2 sm:mt-4 text-base sm:text-base">
                        Vui lòng chọn hoạt động mà bạn muốn tham dự
                        <br />
                        <span className="italic text-xs sm:text-base">
                          ***Note: All times are in Vietnam time (GMT+7)
                        </span>
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        Day1:
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        <span className="uppercase font-bold">
                          Tiệc tối chào mừng{" "}
                          <span className="text-red-600 font-bold">*</span>
                        </span>
                        <br />
                        <span className="italic text-xs">
                          18:00 - 20:30, Thứ 5, Ngày 10/10/2024
                        </span>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full text-blue-950">
                        <CustomSelect
                          data={dataRegisterDay1}
                          placeholder="Tiệc tối chào mừng"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              register_day_1: e.target.value,
                            })
                          }
                          value={formData.register_day_1}
                        />
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        Day2:
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        <span className="uppercase">
                          Hoạt động trong ngày{" "}
                          <span className="text-red-600 font-bold">*</span>
                        </span>
                        <br />
                        <span className="italic text-xs">
                          08:00 - 17:00, Thứ 6, Ngày 11/10/2024
                        </span>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full text-blue-950">
                        <CustomSelect
                          data={dataRegisterDay2Activites}
                          placeholder="Hoạt động trong ngày"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              register_day_2_activites: e.target.value,
                            })
                          }
                          value={formData.register_day_2_activites}
                        />
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        HANDICAP{" "}
                      </div>
                      <span className="italic text-xs">
                        Vui lòng cung cấp thông tin điểm handicap nếu tham gia
                        giải Golf.
                      </span>
                      <CustomInput
                        type="text"
                        placeholder="HANDICAP"
                        value={formData.handicap}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            handicap: e.target.value,
                          })
                        }
                      />
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        DAY 2: GALA DINNER{" "}
                        <span className="text-red-600 font-bold">*</span>
                      </div>
                      <div className="text-base sm:text-xl font-bold">
                        <span className="italic text-xs">
                          Thứ 6, ngày 11/10/2024, 18:00 - 21:00
                        </span>
                      </div>
                      <CustomSelect
                        data={dataRegisterDay2Dinner}
                        placeholder="Hoạt động trong ngày"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            register_day_2_dinner: e.target.value,
                          })
                        }
                        value={formData.register_day_2_dinner}
                      />
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        <span className="uppercase">
                          SIZE ÁO THUN{" "}
                          <span className="text-red-600 font-bold">*</span>
                        </span>
                        <br />
                        <span className="italic text-xs">
                          Vui lòng chọn size áo đồng phục khi tham dự Gala
                          Dinner, tham khảo bảng size bên dưới.
                        </span>
                      </div>
                      <div className="flex flex-col mt-4 w-full text-center mb-5 text-xs sm:text-base">
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            Size áo
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            Chiều cao (m)
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            Cân nặng (Kg)
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full whitespace-nowrap">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            XS
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            &lt;1m50
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            &lt;45
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            S
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m50 - 1m55
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            45 - 50
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            M
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m56 - 1m65
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            51 - 60
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            L
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m66 - 1m70
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            61 - 70
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            XL
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m71 - 1m80
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            71 - 80
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            XXL
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m81 - 1m90
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            81 - 90
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full whitespace-nowrap">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-white">
                            XXXL
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-white">
                            &gt;1m90
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto">
                            &gt;90
                          </div>
                        </div>
                      </div>
                      <CustomSelect
                        data={dataTShirtSize}
                        placeholder="Hoạt động trong ngày"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            t_shirt_size: e.target.value,
                          })
                        }
                        value={formData.t_shirt_size}
                      />
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        Bạn có chế độ ăn đặt biệt nào không?
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full text-blue-950">
                        <CustomSelect
                          data={dataDietaryRestrictions}
                          placeholder="check-in"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dietary_restrictions: e.target.value,
                            })
                          }
                          value={formData.dietary_restrictions}
                        />
                      </div>
                      {formData.dietary_restrictions == "2" ? (
                        <>
                          <span className="italic text-xs">
                            <br />
                            Vui lòng cung cấp thông tin chi tiết trong phần
                            Others
                          </span>
                          <CustomInput
                            type="text"
                            placeholder="Others"
                            value={formData.dietary_restrictions_others}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                dietary_restrictions_others: e.target.value,
                              })
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        Bạn có câu hỏi nào khác dành cho BTC không?
                      </div>
                      <CustomInput
                        type="text"
                        placeholder="other questions"
                        value={formData.other_questions}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            other_questions: e.target.value,
                          })
                        }
                      />
                      {isLoading ? (
                        <button className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors">
                          Đang gửi thông tin
                        </button>
                      ) : (
                        <div className="flex justify-between items-center mt-4 gap-4">
                          <button
                            onClick={handlePrevious}
                            className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                          >
                            Trở lại
                          </button>
                          <button
                            onClick={handleSubmit}
                            className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                          >
                            Đăng ký
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <img
            loading="lazy"
            srcSet={con_thuyen}
            className="relative lg:absolute object-contain  bottom-0 left-0 sm:left-36 z-0 max-w-full h-72 aspect-[1.32] w-[379px]"
          />
        </div>
      </div>
      {isLoading == true ? (
        <>
          <div className="fixed z-20 left-0 top-0 flex min-h-screen flex-col justify-center items-center px-20 py-72 w-full bg-black bg-opacity-80 max-md:px-5 max-md:py-24 max-md:max-w-full">
            <div
              className="text-white flex flex-col justify-center p-8 mb-0 max-w-full rounded-2xl w-[380px] max-md:px-5 max-md:mb-2.5 bg-gradient-to-b from-[#104185] to-[#102f63]"
              style={{ backgroundColor: "rgba(119, 154, 193, 0.8)" }}
            >
              <img
                loading="lazy"
                srcSet={logo}
                className="object-contain self-center max-w-full aspect-[1.85] w-[221px]"
              />
              <div className="mt-4 text-center">
                {responseData ? responseData.message : "Đang xử lý dữ liệu"}
              </div>
              {responseData &&
              responseData.status == false &&
              status == "yes" ? (
                <button
                  onClick={onClosePopup}
                  className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                >
                  Đóng
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {isDone == true ? (
        <>
          <div className="fixed z-20 left-0 top-0 flex min-h-screen flex-col justify-center items-center px-20 py-72 w-full bg-black bg-opacity-80 max-md:px-5 max-md:py-24 max-md:max-w-full">
            <div
              className="text-white flex flex-col justify-center p-8 mb-0 max-w-full rounded-2xl w-[380px] max-md:px-5 max-md:mb-2.5 bg-gradient-to-b from-[#104185] to-[#102f63]"
              style={{ backgroundColor: "rgba(119, 154, 193, 0.8)" }}
            >
              <img
                loading="lazy"
                srcSet={logo}
                className="object-contain self-center max-w-full aspect-[1.85] w-[221px]"
              />
              <div className="mt-4 text-center">
                {status == "no" ? (
                  <>
                    Petrolimex Singapore cảm ơn lời phản hồi của bạn.
                    <br />
                    Hẹn gặp lại trong các sự kiện khác.
                  </>
                ) : (
                  <>
                    Petrolimex Singapore cảm ơn thông tin đăng ký của bạn.
                    <br />
                    Thư xác nhận cùng lịch trình chi tiết sẽ sớm được gửi đến
                    bạn.
                  </>
                )}
              </div>
              {isEdit == true ? (
                <button
                  onClick={closePopupToChange}
                  className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                >
                  Thay đổi
                </button>
              ) : (
                <button className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors">
                  Đóng
                </button>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default RegisterVI;
