import { useEffect, useState } from "react";
import header_mobile_en from "../assets/header-mobile-en.png";
import logo from "../assets/logo.png";
import title_en from "../assets/title_en.png";
import dia_cau from "../assets/dia_cau.png";
import con_thuyen from "../assets/con_thuyen.png";

import CustomInput from "../components/Input";
import { getUserData, logout, updateData } from "../redux/slices/userSlices";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../utils/showToast";
import CustomSelect from "../components/Select";
import {
  postGetUserAPI,
  postRegisterAPI,
} from "../components/api/authentication";

const dataTitle = [
  { label: "Select...", value: "" },
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
];

const dataTimeCheckIn = [
  { label: "Select...", value: "" },
  {
    label: "Check-in: 10 Oct 2024 at 3 PM",
    value: "Check-in: 10 Oct 2024 at 3 PM",
  },
  {
    label: "Check-in: 11 Oct 2024 at 3 PM",
    value: "Check-in: 11 Oct 2024 at 3 PM",
  },
];
const dataHoTroDatXe = [
  { label: "Select...", value: "" },
  { label: "No", value: "0" },
  { label: "Yes", value: "1" },
];
const dataRegisterDay1 = [
  { label: "Select...", value: "" },
  { label: "No", value: "0" },
  { label: "Yes", value: "1" },
];
const dataRegisterDay2Activites = [
  { label: "Select...", value: "" },
  { label: "Mini Golf Tournament", value: "Mini Golf Tournament" },
  { label: "Phu Quoc City Tour", value: "Phu Quoc City Tour" },
  { label: "Free", value: "Free" },
];
const dataRegisterDay2Dinner = [
  { label: "Select...", value: "" },
  { label: "No", value: "0" },
  { label: "Yes", value: "1" },
];
const dataTShirtSize = [
  { label: "Select...", value: "" },
  { label: "XS", value: "XS" },
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "XXL", value: "XXL" },
  { label: "XXXL", value: "XXXL" },
];
const dataDietaryRestrictions = [
  { label: "Select...", value: "" },
  { label: "No", value: "1" },
  {
    label: "Yes",
    value: "2",
  },
];

function RegisterEN() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code, status } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [responseData, setResponseData] = useState<any>(null);
  const [step, setStep] = useState(1);
  const [isDone, setIsDone] = useState(false);
  const dataUser = useSelector(getUserData);

  const [formData, setFormData] = useState({
    token: code,
    status: status,
    language: "en",
    title: "",
    name: "",
    phone: "",
    company: "",
    position: "",
    nationality: "",
    date_of_birth: "",
    passport_number: "",
    passport_expiration_date: "",
    passport_place_of_issue: "",
    time_request_check_in: "",
    airport_transfer_at: "",
    register_day_1: "",
    register_day_2_activites: "",
    handicap: "",
    register_day_2_dinner: "",
    t_shirt_size: "",
    dietary_restrictions: "",
    dietary_restrictions_others: "",
    other_questions: "",
  });

  useEffect(() => {
    if (status == "no") {
      postData();
    } else {
      getUser();
    }
  }, []);

  useEffect(() => {
    console.log("user", dataUser);
    if (dataUser) {
      if (dataUser.status == true) {
        setIsLoading(false);
        setIsDone(true);
      }
    }
  }, [dataUser]);

  const getUser = async () => {
    setIsLoading(true);
    setResponseData(null);
    try {
      const result = await postGetUserAPI(formData);

      console.log("result", result);

      if (result.status == false) {
        setResponseData(result);
      } else {
        setFormData({ ...formData, ...result.user });
        setIsEdit(result.is_edit);
        setIsLoading(false);
        if (result.user.time_register == "") {
          dispatch(logout());
          setResponseData(null);
          setIsLoading(false);
          setIsDone(false);
        } else {
          setIsDone(true);
        }
      }
    } catch (error) {
      setIsLoading(false);
      showToast({
        type: "error",
        message: "An error occurred. Please try again",
      });
    }
  };

  const handleNext = () => {
    console.log("formData", formData);
    if (
      formData.title == "" ||
      formData.name == "" ||
      formData.phone == "" ||
      formData.company == "" ||
      formData.position == "" ||
      formData.nationality == "" ||
      formData.date_of_birth == "" ||
      //   formData.passport_number == "" ||
      //   formData.passport_expiration_date == "" ||
      //   formData.passport_place_of_issue == "" ||
      formData.time_request_check_in == "" ||
      formData.airport_transfer_at.toString() == ""
    ) {
      showToast({ type: "error", message: "Please fill in all information" });
    } else {
      setStep(2);
    }
  };

  const handlePrevious = () => {
    setStep(1);
  };

  const handleSubmit = async () => {
    console.log("formData", formData);
    if (
      formData.register_day_1 === "" ||
      formData.register_day_2_activites === "" ||
      formData.register_day_2_dinner === "" ||
      formData.t_shirt_size === ""
    ) {
      showToast({ type: "error", message: "Please fill in all information" });
    } else {
      postData();
    }
  };

  const postData = async () => {
    setIsLoading(true);
    setResponseData(null);
    try {
      const result = await postRegisterAPI(formData);

      console.log("result", result);

      if (result.status == false) {
        setResponseData(result);
      } else {
        dispatch(updateData(result));
        setIsLoading(false);
        setIsDone(true);
      }
    } catch (error) {
      setIsLoading(false);
      showToast({
        type: "error",
        message: "An error occurred. Please try again.",
      });
    }
  };

  const onClosePopup = () => {
    setIsLoading(false);
  };

  const closePopupToChange = () => {
    setIsDone(false);
  };

  return (
    <>
      <div className="flex overflow-hidden relative flex-col ">
        <div className="flex relative flex-col w-full max-md:max-w-full">
          <div className="flex relative z-10 flex-col flex-1 self-center pt-6 w-full px-5 sm:px-0 sm:w-[1152px] max-w-full">
            <img
              loading="lazy"
              srcSet={header_mobile_en}
              className="block sm:hidden object-contain z-0 self-center mt-8 w-full mb-8"
            />
            <img
              loading="lazy"
              srcSet={logo}
              className="hidden sm:block object-contain z-0 self-center mt-8 w-[221px] mb-8"
            />
            <img
              loading="lazy"
              srcSet={title_en}
              className="hidden sm:block object-contain z-0 self-center max-w-full aspect-[7.3] w-[438px] mb-8"
            />
            <div className="hidden lg:block flex absolute right-0 top-2/4 z-0 flex-col w-80 max-w-full -translate-y-2/4 h-[267px] translate-x-[0%]">
              <div className="flex relative flex-col justify-center items-end px-16 py-9 w-full bg-blend-overlay aspect-[1.203] max-md:px-5">
                <img
                  loading="lazy"
                  srcSet={dia_cau}
                  className="object-cover absolute inset-0 size-full"
                />
              </div>
            </div>
            {/* Box nội dung */}
            <div className="flex justify-center w-full mb-8 z-10">
              <div
                className="w-full max-w-[480px] rounded-2xl overflow-hidden text-white"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom, rgba(16, 65, 133, 0.6), rgba(16, 47, 99, 0.6))",
                  backdropFilter: "blur(5px)",
                }}
              >
                {step == 1 ? (
                  <>
                    <div className="p-6 text-xl font-black text-center text-white uppercase">
                      PERSONAL INFO
                    </div>
                    <div
                      className="px-6 py-4 h-auto sm:h-[calc(100vh-350px)] sm:overflow-y-auto"
                      style={{
                        scrollbarWidth: "thin",
                        scrollbarColor: "#ffffff40 transparent",
                      }}
                    >
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Title{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomSelect
                            data={dataTitle}
                            placeholder="Title"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                title: e.target.value,
                              })
                            }
                            value={formData.title}
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Fullname{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomInput
                            type="text"
                            placeholder="Fullname"
                            value={formData.name}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                name: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Contact number{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <span className="italic text-xs">
                            (country code)(phone no.)
                          </span>
                          <CustomInput
                            type="text"
                            placeholder="Contact number"
                            value={formData.phone}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                phone: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Company{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <span className="italic text-xs">&nbsp;</span>
                          <CustomInput
                            type="text"
                            placeholder="Company"
                            value={formData.company}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                company: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Position{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomInput
                            type="text"
                            placeholder="Position"
                            value={formData.position}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                position: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Nationality{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <CustomInput
                            type="text"
                            placeholder="Nationality"
                            value={formData.nationality}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                nationality: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Date of Birth{" "}
                            <span className="text-red-600 font-bold">*</span>
                          </div>
                          <span className="italic text-xs">
                            Please provide your info for hotel pre-check-in.
                          </span>
                          <span className="italic text-xs">
                            ***Date format: dd/mm/yyyy
                          </span>
                          <CustomInput
                            type="date"
                            placeholder="Date of Birth"
                            value={formData.date_of_birth}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                date_of_birth: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Passport number
                          </div>
                          <span className="italic text-xs">
                            Please provide your info for hotel pre-check-in.
                          </span>
                          <span className="italic text-xs">&nbsp;</span>
                          <CustomInput
                            type="text"
                            placeholder="Passport number "
                            value={formData.passport_number}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                passport_number: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full">
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Passport expiration date
                          </div>
                          <span className="italic text-xs">
                            ***Date format: dd/mm/yyyy
                          </span>
                          <CustomInput
                            type="date"
                            placeholder="Passport expiration date"
                            value={formData.passport_expiration_date}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                passport_expiration_date: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0">
                          <div className="font-bold text-white text-sm sm:text-base">
                            Passport place of issue
                          </div>
                          <span className="italic text-xs">&nbsp;</span>
                          <CustomInput
                            type="text"
                            placeholder="Passport place of issue"
                            value={formData.passport_place_of_issue}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                passport_place_of_issue: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-4 text-white text-sm">
                        We are arranging accommodation for guests at New World
                        Phu Quoc Resort (from 10 to 12 Oct, 2024), please share
                        your schedule at Phu Quoc. Please select the date which
                        you want to check-in ?{" "}
                        <span className="text-red-600 font-bold">*</span>
                        <br />
                        <span className="italic">Check in time: 03 PM</span>
                        <br />
                        <span className="italic">Check out time: 12 PM</span>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full whitespace-nowrap text-blue-950">
                        <CustomSelect
                          data={dataTimeCheckIn}
                          placeholder="check-in"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              time_request_check_in: e.target.value,
                            })
                          }
                          value={formData.time_request_check_in}
                        />
                      </div>
                      <div className="mt-4 text-white text-sm sm:text-base">
                        Do you need support to book airport transfer at/to Phu
                        Quoc airport?{" "}
                        <span className="text-red-600 font-bold">*</span>
                      </div>
                      <div className="mt-4 text-white">
                        <CustomSelect
                          data={dataHoTroDatXe}
                          placeholder="Hỗ trợ đặt xe"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              airport_transfer_at: e.target.value,
                            })
                          }
                          value={formData.airport_transfer_at}
                        />
                      </div>
                      <span className="italic text-xs mt-10">
                        <br />
                        PDPA Disclaimer
                        <br />
                        By providing your personal data, you consent to its
                        collection, use, and disclosure in accordance with the
                        Personal Data Protection Act (PDPA) of Singapore. We
                        will use your data for the purpose of processing your
                        hotel booking and related services. Your information
                        will not be shared with third parties without your
                        consent, unless required by law. You have the right to
                        access and correct your data. For any questions, please
                        contact us at luuchau.tran@petrolimex.com.sg.
                        <br />
                        Thank you!
                      </span>
                      <button
                        onClick={handleNext}
                        className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                      >
                        NEXT
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="p-4 sm:p-6 text-xl font-black text-center text-white uppercase">
                      YOUR ATTENDANCE
                    </div>
                    <div
                      className="px-6 py-2 sm:py-2 h-auto sm:h-[calc(100vh-350px)] sm:overflow-y-auto"
                      style={{
                        scrollbarWidth: "thin",
                        scrollbarColor: "#ffffff40 transparent",
                      }}
                    >
                      <div className="mt-2 sm:mt-4 text-base sm:text-base">
                        Please select the sessions/meetings you wish to join
                        <br />
                        <span className="italic text-xs sm:text-base">
                          ***Note: All times are in Vietnam time (GMT+7)
                        </span>
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        Day1:
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        <span className="uppercase font-bold">
                          RECEPTION DINNER{" "}
                          <span className="text-red-600 font-bold">*</span>
                        </span>
                        <br />
                        <span className="italic text-xs">
                          6:00 PM - 8:30 PM, Thursday - 10 October 2024
                        </span>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full text-blue-950">
                        <CustomSelect
                          data={dataRegisterDay1}
                          placeholder="Tiệc tối chào mừng"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              register_day_1: e.target.value,
                            })
                          }
                          value={formData.register_day_1}
                        />
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        Day2:
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        <span className="uppercase">
                          ACTIVITES{" "}
                          <span className="text-red-600 font-bold">*</span>
                        </span>
                        <br />
                        <span className="italic text-xs">
                          Friday - 11 October 2024, 08:00 - 17:00
                        </span>
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full text-blue-950">
                        <CustomSelect
                          data={dataRegisterDay2Activites}
                          placeholder="Hoạt động trong ngày"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              register_day_2_activites: e.target.value,
                            })
                          }
                          value={formData.register_day_2_activites}
                        />
                      </div>
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        (FOR GOLF TOURNAMENT) LEVEL HCP / HANDICAP{" "}
                      </div>
                      <span className="italic text-xs">
                        Please provide info for Mini Golf Tournament selection.
                      </span>
                      <CustomInput
                        type="text"
                        placeholder="HANDICAP"
                        value={formData.handicap}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            handicap: e.target.value,
                          })
                        }
                      />
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        DAY 2: GALA DINNER{" "}
                        <span className="text-red-600 font-bold">*</span>
                      </div>
                      <div className="text-base sm:text-xl font-bold">
                        <span className="italic text-xs">
                          Friday - 11 October 2024, 18:00 -21:00
                        </span>
                      </div>
                      <CustomSelect
                        data={dataRegisterDay2Dinner}
                        placeholder="Hoạt động trong ngày"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            register_day_2_dinner: e.target.value,
                          })
                        }
                        value={formData.register_day_2_dinner}
                      />
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        <span className="uppercase">
                          T-SHIRT SIZE{" "}
                          <span className="text-red-600 font-bold">*</span>
                        </span>
                        <br />
                        <span className="italic text-xs">
                          Please select your size of T-shirt using in Gala
                          Dinner, size chart as below
                        </span>
                      </div>
                      <div className="flex flex-col mt-4 w-full text-center mb-5 text-xs sm:text-base">
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            Size
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            Height (m)
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            Weight (Kg)
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full whitespace-nowrap">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            XS
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            &lt;1m50
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            &lt;45
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            S
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m50 - 1m55
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            45 - 50
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            M
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m56 - 1m65
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            51 - 60
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            L
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m66 - 1m70
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            61 - 70
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            XL
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m71 - 1m80
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            71 - 80
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto whitespace-nowrap border-r border-b border-white">
                            XXL
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-b border-white">
                            1m81 - 1m90
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-b border-white">
                            81 - 90
                          </div>
                        </div>
                        <div className="flex justify-between items-center w-full whitespace-nowrap">
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-white">
                            XXXL
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto border-r border-white">
                            &gt;1m90
                          </div>
                          <div className="flex-1 shrink gap-2.5 self-stretch py-1 my-auto">
                            &gt;90
                          </div>
                        </div>
                      </div>
                      <CustomSelect
                        data={dataTShirtSize}
                        placeholder="Hoạt động trong ngày"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            t_shirt_size: e.target.value,
                          })
                        }
                        value={formData.t_shirt_size}
                      />
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        Do you have any dietary restrictions?
                      </div>
                      <div className="flex gap-4 items-start mt-4 w-full text-blue-950">
                        <CustomSelect
                          data={dataDietaryRestrictions}
                          placeholder="check-in"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              dietary_restrictions: e.target.value,
                            })
                          }
                          value={formData.dietary_restrictions}
                        />
                      </div>
                      {formData.dietary_restrictions == "2" ? (
                        <>
                          <span className="italic text-xs">
                            <br />
                            Please indicate your specific dietary in Others
                          </span>
                          <CustomInput
                            type="text"
                            placeholder="Others"
                            value={formData.dietary_restrictions_others}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                dietary_restrictions_others: e.target.value,
                              })
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="mt-4 text-base sm:text-xl font-bold">
                        Do you have any other questions / comments?
                      </div>
                      <CustomInput
                        type="text"
                        placeholder="other questions"
                        value={formData.other_questions}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            other_questions: e.target.value,
                          })
                        }
                      />
                      {isLoading ? (
                        <button className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors">
                          Sending...
                        </button>
                      ) : (
                        <div className="flex justify-between items-center mt-4 gap-4">
                          <button
                            onClick={handlePrevious}
                            className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                          >
                            Back
                          </button>
                          <button
                            onClick={handleSubmit}
                            className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                          >
                            Register
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <img
            loading="lazy"
            srcSet={con_thuyen}
            className="relative lg:absolute object-contain  bottom-0 left-0 sm:left-36 z-0 max-w-full h-72 aspect-[1.32] w-[379px]"
          />
        </div>
      </div>
      {isLoading == true ? (
        <>
          <div className="fixed z-20 left-0 top-0 flex min-h-screen flex-col justify-center items-center px-20 py-72 w-full bg-black bg-opacity-80 max-md:px-5 max-md:py-24 max-md:max-w-full">
            <div
              className="text-white flex flex-col justify-center p-8 mb-0 max-w-full rounded-2xl w-[380px] max-md:px-5 max-md:mb-2.5 bg-gradient-to-b from-[#104185] to-[#102f63]"
              style={{ backgroundColor: "rgba(119, 154, 193, 0.8)" }}
            >
              <img
                loading="lazy"
                srcSet={logo}
                className="object-contain self-center max-w-full aspect-[1.85] w-[221px]"
              />
              <div className="mt-4 text-center">
                {responseData ? responseData.message : "Loading..."}
              </div>
              {responseData &&
              responseData.status == false &&
              status == "yes" ? (
                <button
                  onClick={onClosePopup}
                  className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                >
                  Close
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {isDone == true ? (
        <>
          <div className="fixed z-20 left-0 top-0 flex min-h-screen flex-col justify-center items-center px-20 py-72 w-full bg-black bg-opacity-80 max-md:px-5 max-md:py-24 max-md:max-w-full">
            <div
              className="text-white flex flex-col justify-center p-8 mb-0 max-w-full rounded-2xl w-[380px] max-md:px-5 max-md:mb-2.5 bg-gradient-to-b from-[#104185] to-[#102f63]"
              style={{ backgroundColor: "rgba(119, 154, 193, 0.8)" }}
            >
              <img
                loading="lazy"
                srcSet={logo}
                className="object-contain self-center max-w-full aspect-[1.85] w-[221px]"
              />
              <div className="mt-4 text-center">
                {status == "no" ? (
                  <>
                    Thank you for your response.
                    <br />
                    Hope to see you in the future event.
                  </>
                ) : (
                  <>
                    Thank you for your registration.
                    <br />
                    Confirmation letter along with the detailed schedule will be
                    sent to you soon.
                  </>
                )}
              </div>
              {isEdit == true ? (
                <button
                  onClick={closePopupToChange}
                  className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors"
                >
                  Change information
                </button>
              ) : (
                <button className="w-full px-4 py-3 mt-4 font-bold text-white bg-orange-500 rounded-[1000px] hover:bg-orange-600 transition-colors">
                  Done
                </button>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default RegisterEN;
