import { useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import ProtectedRouter from "./auth/ProtectedRouter";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import DataContext from "./context/DataContext";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/Register";
import RegisterVI from "./pages/RegisterVI";
import RegisterEN from "./pages/RegisterEN";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

const router = createBrowserRouter([
  {
    path: "/*",
    element: <ProtectedRouter />,
    children: [
      { path: "", element: <Home /> },
      { path: "register/vi/:code/:status", element: <RegisterVI /> },
      { path: "register/en/:code/:status", element: <RegisterEN /> },
    ],
  },
]);

function App() {
  const [user, setUser] = useState(null);

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      <div className="font-samsung w-full h-full min-h-screen bg-gradient-custom">
        <RouterProvider router={router} />
      </div>
    </DataContext.Provider>
  );
}

export default App;
